(function (angular) {
    angular.module('MyHippoProducer.Controllers').controller('DashboardController', DashboardController);
    DashboardController.$inject = ['$log', '$scope', 'APIService', 'TimeRangeFilterService', 'UserService'];
    function DashboardController ($log, $scope, APIService, TimeRangeFilterService, UserService) {
        $log.info('DashboardController');
        $scope.shouldDisablePolicyCreation = UserService.isPolicyCreationDisabled();
        $scope.isAdmin = UserService.isAdmin();

        $scope.filters = {
            scope: 'producer',
            timeRange: TimeRangeFilterService.getTimeRange()
        };

        $scope.findStats = function () {
            APIService.findDashboardStats($scope.filters).then(stats => {
                $scope.stats = stats;
            });
        };
        $scope.findStats();

        $scope.filterPolicies = function () {
            TimeRangeFilterService.setTimeRange($scope.filters.timeRange);
            APIService.findDashboardStats($scope.filters).then(stats => {
                $scope.stats = stats;
            });
        };
    }
})(window.angular);
